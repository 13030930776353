<template>
  <div class="AccordDetails" >
    <formTitleComponent />
      <div class="container">
        <h1 class="form_div_title text-center">Ma demande d'accord de {{accord.year}}</h1>
        <div class="form_div">
          <div class="form_content">

            <b-modal ref="bankInformationAPILoadingErrorModal" id="bankInformationAPILoadingErrorModal" hide-footer title="Erreur lors de l'enregistrement de vos informations">
              <div class="text-center">
                <p>Une erreur s'est produite lors de l'enregistrement de vos informations bancaires,
                  veuillez vérifier votre iban ou nous contacter directement si le problème persiste.
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('bankInformationAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Ask validation Accord -->
            <b-modal ref="askAccordValidationModal" size="lg" id="askAccordValidationModal" hide-footer title="Votre demande de validation a bien été prise en compte">
              <div class="text-center">
                <p><strong>Votre demande de validation de votre dossier a bien été prise en compte</strong>.
                  Un.e conseiller.ère Audiens étudiera vos informations au plus vite </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('askAccordValidationModal')">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Document Uploaded -->
            <b-modal ref="accordDocumentUploadedModal" size="lg" id="accordDocumentUploadedModal" hide-footer title="Votre document a bien été envoyé et enregistré">
              <div class="text-center">
                <p><strong>Votre document a bien été envoyé.</strong> <br>
                  Une fois tous vos documents chargés, vous pourrez demander la validation finale de votre demande d'accord
                  auprès d'un.e conseiller.ère Audiens
                </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('accordDocumentUploadedModal')">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Document Deleted -->
            <b-modal ref="documentDeletedModal" size="lg" id="documentDeletedModal" hide-footer title="Votre document a bien été supprimé">
              <div class="text-center">
                <p><strong>Votre document a bien été supprimé.</strong> <br>
                  Une fois tous vos documents chargés, vous pouvez demander la validation finale de votre demande d'accord
                  auprès d'un.e conseiller.ère Audiens
                </p>
                <b-button class="mt-3" variant="outline-success" @click="$bvModal.hide('documentDeletedModal')">Fermer</b-button>
              </div>
            </b-modal>


            <!-- Create Document -->
            <b-modal ref="createAccordDocumentModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Charger votre document </strong>
              </template>
              <div>
                <p>
                  <small v-if="accord.state == '2ONGOING'" class="text-info">
                    <strong>Attention, votre dossier est en cours de validation.</strong>
                      Si vous chargez ou modifiez un document, le processus de demande de validation sera remis à zéro
                  </small>
                </p>
                <b-form>
                  <b-form-group>
                      <b-form-file
                        class="text-truncate"
                        browse-text='Parcourir'
                        required
                        v-model="createAccordDocumentFile"
                        id="create-document-file-input"
                        placeholder="Choisissez un fichier"
                        drop-placeholder="Déposez un fichier ici"
                        @input="$v.createAccordDocumentFile.$touch()"
                        :state="$v.createAccordDocumentFile.$dirty ? !$v.createAccordDocumentFile.$error : null"
                      ></b-form-file>
                      <div class="form-error" v-if="!$v.createAccordDocumentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
                      <div class="form-error" v-if="!$v.createAccordDocumentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
                      <div class="form-error" v-if="!$v.createAccordDocumentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
                      <div class="form-error" v-if="!$v.createAccordDocumentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
                  </b-form-group>
                  <b-button
                    pill block :variant="createAccordDocumentButtonStyle" class="mt-2"
                    @click='createAccordDocument'
                    :disabled="createAccordDocumentInProcess || $v.createAccordDocumentFile.$invalid  ">
                      <b-spinner small label="Upload document Spinner" v-if="createAccordDocumentInProcess"></b-spinner> {{ createAccordDocumentButtonText }}
                  </b-button>
                </b-form>
              </div>
            </b-modal>

            <!-- Modify Document -->
            <b-modal ref="modifyAccordDocumentModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Modifier votre document </strong>
              </template>
              <div>
                <p>
                  <small v-if="accord.state == '2ONGOING'" class="text-info">
                    <strong>Attention, votre dossier est en cours de validation.</strong>
                      Si vous chargez ou modifiez un document, le processus de demande de validation sera remis à zéro
                  </small>
                </p>
                <b-form>
                  <b-form-group>
                      <b-form-file
                        class="text-truncate"
                        browse-text='Parcourir'
                        required
                        v-model="modifyAccordDocumentFile"
                        id="modify-document-file-input"
                        placeholder="Choisissez un fichier"
                        drop-placeholder="Déposez un fichier ici"
                        @input="$v.modifyAccordDocumentFile.$touch()"
                        :state="$v.modifyAccordDocumentFile.$dirty ? !$v.modifyAccordDocumentFile.$error : null"
                      ></b-form-file>
                      <div class="form-error" v-if="!$v.modifyAccordDocumentFile.file_size_validation">Attention, la taille de votre document ne doit pas dépasser 2 MB.</div>
                      <div class="form-error" v-if="!$v.modifyAccordDocumentFile.file_extension_validation">Attention, votre fichier doit être au format pdf, jpg, jpeg ou png</div>
                      <div class="form-error" v-if="!$v.modifyAccordDocumentFile.name.maxLength">Assurez-vous que le nom de votre fichier comporte au plus 100 caractères</div>
                      <div class="form-error" v-if="!$v.modifyAccordDocumentFile.name.minLength">Assurez-vous que le nom de votre fichier comporte au moins 2 caractères</div>
                  </b-form-group>

                  <b-button
                    pill block :variant="modifyAccordDocumentButtonStyle" class="mt-2"
                    @click='modifyAccordDocument'
                    :disabled="modifyAccordDocumentInProcess || $v.modifyAccordDocumentFile.$invalid  ">
                      <b-spinner small label="Upload document Spinner" v-if="modifyAccordDocumentInProcess"></b-spinner> {{ modifyAccordDocumentButtonText }}
                  </b-button>
                </b-form>
              </div>
            </b-modal>

            <!-- Modify Document -->
            <b-modal ref="deleteAccordDocumentModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Supprimer votre document </strong>
              </template>
              <div>
                <p>
                  Attention, si vous supprimez ce document, votre conseiller.ère Audiens ne pourrra plus y avoir accès.
                </p>
                <p>
                  <small v-if="accord.state == '2ONGOING'" class="text-info">
                    <strong>Attention, votre dossier est en cours de validation.</strong>
                      Si vous chargez ou modifiez un document, le processus de demande de validation sera remis à zéro
                  </small>
                </p>
                <b-button
                  pill block :variant="deleteAccordDocumentButtonStyle" class="mt-2"
                  @click='deleteAccordDocument'
                  :disabled="deleteAccordDocumentInProcess">
                    <b-spinner small label="Upload document Spinner" v-if="deleteAccordDocumentInProcess"></b-spinner> {{ deleteAccordDocumentButtonText }}
                </b-button>
              </div>
            </b-modal>

            <b-modal ref="globalAPILoadingErrorModal" id="globalAPILoadingErrorModal" hide-footer title="Erreur lors du chargement de votre profil">
              <div class="text-center">
                <p>Une erreur s'est produite lors du chargement des informations de votre accord,
                  veuillez réactualiser la page ou nous contacter directement.
                </p>
                <b-button class="mt-3" variant="outline-dark" @click="$bvModal.hide('globalAPILoadingErrorModal')">Fermer</b-button>
              </div>
            </b-modal>

            <!-- Create Document -->
            <b-modal v-model="askAccordValidationModal" size="lg" hide-footer>
              <template v-slot:modal-title>
                <strong> Demande de validation de votre accord de principe </strong>
              </template>
              <div>
                <b-form-group>
                  <b-form-checkbox
                    id="accord-is_no_other_aides"
                    v-model="isNoOtherAides"
                    name="accord-is_no_other_aides"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Je certifie sur l'honneur ne percevoir aucune autre aide liée à la garde d'enfants
                    (aide du département, de la région, d'une entreprise par exemple)
                    pour les dépenses dont je demande le remboursement.
                  </b-form-checkbox>
                </b-form-group>

                <b-form-group>
                  <b-form-checkbox
                    id="accord-is_information_certified"
                    v-model="isInformationCertified"
                    name="accord-is_information_certified"
                    :value="true"
                    :unchecked-value="false"
                  >
                    Je certifie l'exactitude des informations fournies.
                  </b-form-checkbox>
                </b-form-group>
                <p>
                  <strong>
                    Je suis conscient qu'une fausse déclaration m'expose à des poursuites et que cette attestation pourra être utilisé en justice
                  </strong>
                </p>
                <b-button
                  pill block variant="outline-success" class="mt-2"
                  @click='askAccordValidation'
                  :disabled="modifyAccordInProcess || !isNoOtherAides || !isInformationCertified">
                    Confirmer la demande de validation finale de votre dossier
                </b-button>

              </div>
            </b-modal>

            <loading
              :active.sync="isLoading"
              :can-cancel="false"
              :is-full-page="true"
              color="#BB3634">
            </loading>

            <div class="row mt-4">
              <div class="col-12">
                  Sur cette page, vous pouvez charger tous les documents nécessaires à la validation de votre demande d'accord.<br>
                  Une fois <strong>vos documents chargés</strong>  sur la plateforme, cliquez sur le bouton "Demandez la validation finale de mon accord"
                  pour que votre dossier soit étudié par un.e conseiller.ère Audiens.
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12">
                <strong style="font-size: 1.2em;"> Statut de votre demande d'accord  :
                  <span v-if="accord.state == '1CREATED'" class="text-info" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="edit" scale="1.5"/>
                    <span class="ml-1">Documents à charger puis demande de validation à faire</span>
                  </span>
                  <span v-if="accord.state == '2ONGOING'" class="text-secondary" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="dot-circle" scale="1.4"/>
                    <span class="ml-1">En cours de validation par votre conseiller.ère Audiens</span>
                  </span>
                  <span v-if="accord.state == '4CREFUSED' || accord.state == '5CVALIDATED'" class="text-secondary" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="dot-circle" scale="1.4"/>
                    <span class="ml-1">Prise en charge par votre conseiller.ère Audiens</span>
                  </span>
                  <span v-if="accord.state == '3INCOMPLET'" class="text-danger" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="times-circle" scale="1.4"/>
                    <span class="ml-1">Dossier à compléter, veuillez corriger vos informations et réactualiser votre demande</span>
                  </span>
                  <span v-if="accord.state == '3IMPOTINCOMPLET'" class="text-danger" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="times-circle" scale="1.4"/>
                    <span class="ml-1">Dossier à compléter, veuillez ajouter des documents en plus de votre avis d'imposition</span>
                  </span>
                  <span v-if="accord.state == '6MREFUSED'" class="text-danger" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="times-circle" scale="1.4"/>
                    <span class="ml-1">Dossier refusé</span>
                  </span>
                  <span v-if="accord.state == '7MVALIDATED'" class="text-success" style="display: inline-flex; vertical-align: middle;">
                    <v-icon name="check-circle" scale="1.4"/>
                    <span class="ml-1">Validé par votre conseiller.ère Audiens</span><br>
                  </span>
                </strong>
              </div>
            </div>

            <div v-if="accord.state == '2ONGOING' || accord.state == '4CREFUSED' || accord.state == '5CVALIDATED'" class="row mt-2">
              <div class="col-12">
                <strong> Vous recevrez un mail de confirmation dés traitement de votre dossier </strong> <br>
                <small><em> Attention, n'oubliez pas de vérifier vos spams </em></small>
              </div>
            </div>
            <div v-if="accord.state == '3INCOMPLET' || accord.state == '3IMPOTINCOMPLET' || accord.state == '6MREFUSED'" class="row mt-2">
              <div v-if="accord.state == '3IMPOTINCOMPLET'" class="col-12">
                <strong>Documents supplémentaires à charger :</strong><br>
                <span v-if="accord.is_avis_situation_mandatory">- Avis de situation déclarative à l'impôt sur le revenu </span><br>
                <span v-if="accord.is_teleactualisation_mandatory">- Trois dernières téléactualisations de France Travail </span><br>
                <span v-if="accord.is_attestation_paiement_mandatory">- Attestations de paiement de France Travail</span><br>
              </div>
              <div v-if="accord.refused_message" class="col-12">
                <strong> Message de votre conseiller.ère Audiens :</strong> <em> "{{ accord.refused_message}}" </em>
              </div>
            </div>
            <div v-if="accord.state == '7MVALIDATED'" class="row mt-3 mb-3">
              <div class="col-12">
                <strong style="font-size: 1.2em;">
                  Votre demande d'accord de principe pour l'année {{accord.year}} a été validée.<br>
                  Vous pouvez faire des demandes de remboursement
                  pour les mois de l'année {{accord.year}}.
                </strong>
              </div>
            </div>

            <div v-if="accord.state === '2ONGOING' || accord.state == '4CREFUSED' || accord.state == '5CVALIDATED' || accord.state == '6MREFUSED' || accord.state == '7MVALIDATED'">
            </div>
            <div v-else-if="accord.state === '1CREATED' && accord.year < currentYear && (accord.first_ask_validation_date == null || accord.first_ask_validation_date == '')" class="text-center">
              <h2 class="text-danger mt-3">
                Vous ne pouvez faire une demande de validation pour un accord d'une année antérieure à l'année en cours.<br>
                Il vous est nécessaire de créer une nouvelle demande d'accord pour l'année en cours
              </h2>
            </div>
            <div v-else-if="!accord.rfr_value || (!accord.rfr_nb_children_fiscalement_a_charge && accord.rfr_nb_children_fiscalement_a_charge !== 0)" class="text-center">
              <h2 class="text-danger mt-3">
                Veuillez renseigner et enregistrer votre revenu de référence (et celui de votre conjoint le cas échéant) et 
                votre nombre d'enfant fiscalement à charge
              </h2>
            </div>
            <div v-else-if="rfrTotal > rfrPlafond" class="text-center">
              <h2 class="text-danger mt-3">
                Votre revenu de référence
                ({{formatThousandSeparatorNumber(rfrTotal)}} €) est supérieur
                au plafond autorisé
                ({{formatThousandSeparatorNumber(rfrPlafond)}} €) <br>pour pouvoir
                réaliser une demande d'aide
              </h2>
            </div>
            <div v-else-if="!hasFilledAllInformation"
                 class="text-center">
              <h2 class="text-danger mt-3"> Veuillez télécharger tous vos documents et renseigner votre RIB (iban, bic et domiciliation bancaire) pour
              pouvoir faire une demande de validation finale de votre dossier.</h2>
            </div>
            <div v-else-if="!hasUploadedNotificationOuvertureAreForCouple">
              <h2 class="text-danger mt-3">
                Veuillez télécharger les notifications d'ouverture de droits ARE de France Travail de <u>votre conjoint</u>
                et de <u>vous-même</u> (2 documents )
              </h2>
            </div>
            <div v-else-if="isFamilleMonoparentale && attestCafDocs.length === 0">
              <h2 class="text-danger mt-3">
                Veuillez charger votre ou vos attestation(s) de paiement de la CAF
              </h2>
            </div>
            <div v-else class="row mt-3">
              <div class="col-sm-12">
                <div
                  v-if="isBeforeDeadline"
                >
                  <b-button
                    block variant="outline-success" class="mt-2 font-weight-bold" size="lg"
                    @click="preAskAccordValidation"
                    :disabled="modifyAccordInProcess">
                       Demander la validation finale de votre dossier
                  </b-button>
                  <p
                    v-if="isOneDocumentRefused"
                    class="text-danger mt-2"
                  >
                    <strong><u>Attention</u>, au moins un de vos documents a été refusé par votre conseiller.ère Audiens.</strong>
                  </p>
                </div>
                <strong
                  class="text-danger"
                  v-else
                >
                  Il n'est plus possible de faire une demande de validation 6 mois après le mois de décembre de l'année {{accord.year}}
                </strong>
              </div>
            </div>


            <!-- //////////////////////// REVENU FISCAL DE REFERENCE //////////////// -->
            <div class="row mt-3">
              <div class="col-12">
                <h3 style="margin-bottom: 0px;"> Revenu fiscal </h3>
                <em class="text-secondary">
                  Veuillez renseigner les informations liées à votre revenu fiscal.
                </em>
              </div>
            </div>
            <div class='row mt-2'>
              <div class="col-12">
                <p>
                  Situation que vous avez renseignée dans votre profil : <strong>{{situationRender(accord.beneficiaire.situation)}}</strong>
                </p>
                <b-form-group
                    id="accord-rfrValue-group"
                  :label="enCouple ?
                            'Revenu brut global du couple (ou votre revenu brut global en cas de déclaration séparée) indiqué sur votre avis d\'imposition N-2' :
                            'Votre revenu brut global indiqué sur votre avis d\'imposition N-2'
                          "
                  label-for="accord-rfrValue-input"
                  class="mandatory-input"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    v-model.trim.lazy="rfrValue"
                    id="accord-rfrValue-input"
                    @input="$v.rfrValue.$touch()"
                    :state="$v.rfrValue.$dirty ? !$v.rfrValue.$error : null"
                    placeholder="Revenu brut global en euros">
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  v-if="enCouple"
                  id="accord-rfrConjointValue-group"
                  label="Revenu brut global de votre conjoint (en cas de déclaration séparée)"
                  label-for="accord-rfrConjointValue-input"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    v-model.trim.lazy="rfrConjointValue"
                    id="accord-rfrConjointValue-input"
                    @input="$v.rfrConjointValue.$touch()"
                    :state="$v.rfrConjointValue.$dirty ? !$v.rfrConjointValue.$error : null"
                    placeholder="Revenu brut global de votre conjoint en euros">
                  </b-form-input>
                </b-form-group>

                <b-form-group
                  id="accord-rfrNbEnfantsCharge-group"
                  label="Nombre d'enfants fiscalement à charge"
                  label-for="accord-rfrNbEnfantsCharge-input"
                  class="mandatory-input"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    v-model.trim.lazy="rfrNbEnfantsCharge"
                    id="accord-rfrNbEnfantsCharge-input"
                    @input="$v.rfrNbEnfantsCharge.$touch()"
                    :state="$v.rfrNbEnfantsCharge.$dirty ? !$v.rfrNbEnfantsCharge.$error : null"
                    placeholder="Nombre d'enfants">
                  </b-form-input>
                </b-form-group>
              </div>
            </div>

            <div class="row">
              <div
                v-if="!enCouple"
                  class="col-12 col-md-4 mt-2"
              >
                <p>
                  Vos abattements fiscaux (du demandeur)
                </p>
                <div
                  class="abattement-item"
                  v-for="(abattement, index) in abattements" :key="`abattements-${index}`"
                >
                  <div>{{abattement.montant}} €</div>
                  <div
                    @click="deleteAbattement(abattement.id)"
                    class="abattement-item--close-window"
                    v-b-popover.hover.top="'Supprimer'"
                  >
                    <div class="abattement-item--close-window--cross">&#x2716;</div>
                  </div>
                </div>

                <b-input-group
                  v-if="abattements.length < 6"
                  class="mt-3"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    @keyup.enter="addAbattement('DEMANDEUR')"
                    v-model="abattementValue"
                  />
                  <b-input-group-append>
                    <b-button
                      @click="addAbattement('DEMANDEUR')"
                      variant="outline-success"
                    >
                      Ajouter
                    </b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div
                v-if="enCouple"
                class="col-12 col-md-4 mt-2"
              >
                <p>
                  Abattements fiscaux du couple <br>
                  <small>(ou les vôtres en cas de déclaration séparée)</small>
                </p>
                <div
                  class="abattement-item"
                  v-for="(abattement, index) in abattementsCouple" :key="`abattements-couple-${index}`"
                >
                  <div>{{abattement.montant}} €</div>
                  <div
                    @click="deleteAbattement(abattement.id)"
                    class="abattement-item--close-window"
                    v-b-popover.hover.top="'Supprimer'"
                  >
                    <div class="abattement-item--close-window--cross">&#x2716;</div>
                  </div>
                </div>
                <b-input-group
                  v-if="abattementsCouple.length < 6"
                  class="mt-3"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    v-model="abattementCoupleValue"
                    @keyup.enter="addAbattement('COUPLE')"
                  />
                  <b-input-group-append>
                    <b-button @click="addAbattement('COUPLE')" variant="outline-success">Ajouter</b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
              <div
                v-if="enCouple"
                class="col-12 col-md-4 mt-2"
              >
                <p>
                  Abattements fiscaux de votre conjoint <br>
                  <small>(en cas de déclaration séparée)</small>
                </p>
                <div
                  class="abattement-item"
                  v-for="(abattement, index) in abattementsConjoint" :key="`abattements-conjoint-${index}`"
                >
                  <div>{{abattement.montant}} €</div>
                  <div
                    @click="deleteAbattement(abattement.id)"
                    class="abattement-item--close-window"
                    v-b-popover.hover.top="'Supprimer'"
                  >
                    <div class="abattement-item--close-window--cross">&#x2716;</div>
                  </div>
                </div>
                <b-input-group
                  v-if="abattementsConjoint.length < 6"
                  class="mt-3"
                >
                  <b-form-input
                    type="number"
                    step="1"
                    v-model="abattementConjointValue"
                    @keyup.enter="addAbattement('CONJOINT')"
                  />
                  <b-input-group-append>
                    <b-button @click="addAbattement('CONJOINT')" variant="outline-success">Ajouter</b-button>
                  </b-input-group-append>
                </b-input-group>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-sm-12 text-center">
                <b-button
                  block variant="outline-success" size="sm" pill
                  @click="modifyAccordRfrInformation"
                  :disabled="modifyAccordInProcess || $v.rfrValue.$invalid || $v.rfrConjointValue.$invalid || $v.rfrNbEnfantsCharge.$invalid
                    || accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
                    Enregistrer ces informations
                </b-button>
                <small v-if="accord.state === '6MREFUSED'" class="text-danger">Demande d'accord refusée</small>
                <small v-if="accord.state === '7MVALIDATED'" class="text-success">Demande d'accord déjà acceptée</small>
              </div>
            </div>

            <p class="mt-2" v-if="rfrTotal > rfrPlafond">
              <strong class="text-danger">
                Votre revenu de référence
                ({{formatThousandSeparatorNumber(rfrTotal)}} €) est supérieur
                au plafond autorisé
                ({{formatThousandSeparatorNumber(rfrPlafond)}} €) pour pouvoir
                réaliser une demande d'aide
              </strong>
            </p>

            <hr>

            <!-- //////////////////////// ACTE DE NAISSANCE //////////////// -->
            <div class="row mt-3">
              <div class="col-12">
                <h3 style="margin-bottom: 0px;"> Acte(s) de naissance ou d'adoption </h3>
                <em class="text-secondary">
                  Veuillez charger le(s) acte(s) de naissance ou d'adoption de ou des enfant(s) faisant l'objet de la garde.
                </em><br>

              </div>
            </div>

            <div v-if="naissanceDocs.length === 0">
              <div class="row">
                <div class="col-sm-12 mt-2 mb-3 text-center">
                  <em>Aucun document encore chargé </em>
                </div>
              </div>
            </div>

            <div v-else>
              <b-table
                responsive class="align-middle text-center mt-4 cursor-table"
                headVariant="light"
                :fields="documentsRenderFields"
                :items="naissanceDocs"
                >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(file_name)="data">
                  Acte de naissance<br>
                  <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                </template>

                <template v-slot:cell(state)="data">
                  <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                  <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                  <strong v-else class="text-secondary">En cours de validation</strong>
                </template>

                <template v-slot:cell(actions)="data">
                  <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                    <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'>
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                    <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                  </div>
                  <div v-else>
                    <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-primary" class="ml-2" size="sm"
                      @click='showModifyAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Modifier'">
                      <v-icon name="edit"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-danger" class="ml-2" size="sm"
                      @click='showDeleteAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Supprimer'">
                      <v-icon name="trash-alt"/>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div v-if="naissanceDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
              <div class="col-sm-12 text-right">
                <b-button
                  v-if="isBeforeDeadline"
                  variant="outline-info" size="sm" pill
                  @click="showCreateAccordDocumentModal('NAISSANCE')">
                    <v-icon name="plus" /> Ajouter un fichier
                </b-button>
                <strong
                  v-else
                >
                  Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                </strong>
              </div>
            </div>

            <hr>

            <!-- //////////////////////// AVIS D'IMPOSITION //////////////// -->
            <div class="row mt-3">
              <div class="col-12">
                <h3 style="margin-bottom: 0px;"> Avis d'imposition </h3>
                <em class="text-secondary">
                  Veuillez charger la copie intégrale de l'avis d'imposition de l'année {{accord.year - 1}} sur les revenus {{accord.year - 2}}
                  du bénéficiaire ainsi que celui du conjoint en cas de vie maritale.<br>
                </em>
              </div>
            </div>

            <div v-if="impotDocs.length === 0">
              <div class="row">
                <div class="col-sm-12 mt-2 mb-3 text-center">
                  <em>Aucun document encore chargé </em>
                </div>
              </div>
            </div>

            <div v-else>
              <b-table
                responsive class="align-middle text-center mt-4 cursor-table"
                headVariant="light"
                :fields="documentsRenderFields"
                :items="impotDocs"
                >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(file_name)="data">
                  Avis d'imposition<br>
                  <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                </template>

                <template v-slot:cell(state)="data">
                  <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                  <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                  <strong v-else class="text-secondary">En cours de validation</strong>
                </template>

                <template v-slot:cell(actions)="data">
                  <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                    <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'>
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                    <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                  </div>
                  <div v-else>
                    <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-primary" class="ml-2" size="sm"
                      @click='showModifyAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Modifier'">
                      <v-icon name="edit"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-danger" class="ml-2" size="sm"
                      @click='showDeleteAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Supprimer'">
                      <v-icon name="trash-alt"/>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div v-if="impotDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
              <div class="col-sm-12 text-right">
                <b-button
                  v-if="isBeforeDeadline"
                  variant="outline-info" size="sm" pill
                  @click="showCreateAccordDocumentModal('IMPOTS')">
                    <v-icon name="plus" /> Ajouter un fichier
                </b-button>
                <strong
                  v-else
                >
                  Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                </strong>
              </div>
            </div>

            <hr>


            <!-- //////////////////////// AVIS DE DE SITUATION DECLARATIVE //////////////// -->
            <div v-if="accord.is_avis_situation_mandatory">
              <div class="row mt-3">
                <div class="col-12">
                  <h3 style="margin-bottom: 0px;"> Avis de situation déclarative à l'impôt sur le revenu </h3>
                  <em class="text-secondary">
                    Veuillez charger la copie intégrale de votre déclaration à l'impôts sur le revenu de l'année {{accord.year}} sur les revenus {{accord.year - 1}}
                    du bénéficiaire ainsi que celui du conjoint en cas de vie maritale.<br>
                  </em>
                </div>
              </div>

              <div v-if="avisSituationDocs.length === 0">
                <div class="row">
                  <div class="col-sm-12 mt-2 mb-3 text-center">
                    <em>Aucun document encore chargé </em>
                  </div>
                </div>
              </div>

              <div v-else>
                <b-table
                  responsive class="align-middle text-center mt-4 cursor-table"
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="avisSituationDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Avis de situation déclarative<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                      <b-button
                        variant="outline-secondary" class="mr-2" size="sm"
                        @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                      <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyAccordDocumentModal(data.item.id)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteAccordDocumentModal(data.item.id)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="avisSituationDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateAccordDocumentModal('AVSITUA')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                  </strong>
                </div>
              </div>

              <hr>
            </div>

            <!-- //////////////////////// TELEACTUALISATION DE POLE EMPLOI //////////////// -->
            <div v-if="accord.is_teleactualisation_mandatory">
              <div class="row mt-3">
                <div class="col-12">
                  <h3 style="margin-bottom: 0px;"> Trois dernières téléactualisations de France Travail </h3>
                  <em class="text-secondary">
                    Veuillez charger vos trois dernières téléactualisations de France Travail.<br>
                  </em>
                </div>
              </div>

              <div v-if="teleactualisationDocs.length === 0">
                <div class="row">
                  <div class="col-sm-12 mt-2 mb-3 text-center">
                    <em>Aucun document encore chargé </em>
                  </div>
                </div>
              </div>

              <div v-else>
                <b-table
                  responsive class="align-middle text-center mt-4 cursor-table"
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="teleactualisationDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Teleactualisation<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                      <b-button
                        variant="outline-secondary" class="mr-2" size="sm"
                        @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                      <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyAccordDocumentModal(data.item.id)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteAccordDocumentModal(data.item.id)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="teleactualisationDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if=isBeforeDeadline
                    variant="outline-info" size="sm" pill
                    @click="showCreateAccordDocumentModal('TELEACTU')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                  </strong>
                </div>
              </div>

              <hr>
            </div>

            <div v-if="accord.is_attestation_paiement_mandatory">
              <div class="row mt-3">
                <div class="col-12">
                  <h3 style="margin-bottom: 0px;"> Attestations de paiement de France Travail </h3>
                  <em class="text-secondary">
                    Veuillez charger votre ou vos attestation(s) qui indique(nt) les denières paiements perçus par France Travail.<br>
                  </em>
                </div>
              </div>

              <div v-if="attestationsPaiementDocs.length === 0">
                <div class="row">
                  <div class="col-sm-12 mt-2 mb-3 text-center">
                    <em>Aucun document encore chargé </em>
                  </div>
                </div>
              </div>

              <div v-else>
                <b-table
                  responsive class="align-middle text-center mt-4 cursor-table"
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="attestationsPaiementDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Attestation de paiement<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                      <b-button
                        variant="outline-secondary" class="mr-2" size="sm"
                        @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                      <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyAccordDocumentModal(data.item.id)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteAccordDocumentModal(data.item.id)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="attestationsPaiementDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateAccordDocumentModal('PAIEPE')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                  </strong>
                </div>
              </div>

              <hr>
            </div>


            <!-- //////////////////////// NOTIFICATION D'OUVERTURE DES DROITS ARE //////////////// -->
            <div class="row mt-3">
              <div class="col-12">
                <h3 style="margin-bottom: 0px;">Notification(s) d'ouverture de vos droits ARE de France Travail</h3>
                <em class="text-secondary">
                  Veuillez charger la dernière notification d'ouverture de vos droits ARE de France Travail au titre des annexes 8 ou 10.
                  <span class="text-danger" v-if="(accord.beneficiaire.situation === 'MAR' || accord.beneficiaire.situation === 'PACS' || accord.beneficiaire.situation === 'VIEMAR') && accord.beneficiaire.is_conjoint_intermittent">
                    <br>Vous avez déclaré un conjoint intermittent, veuillez également charger sa dernière notification d'ouverture de droits
                  </span>
                </em><br>
              </div>
            </div>

            <div v-if="ouvertureAreDocs.length === 0">
              <div class="row">
                <div class="col-sm-12 mt-2 mb-3 text-center">
                  <em>Aucun document encore chargé </em>
                </div>
              </div>
            </div>

            <div v-else>
              <b-table
                responsive class="align-middle text-center mt-4 cursor-table"
                headVariant="light"
                :fields="documentsRenderFields"
                :items="ouvertureAreDocs"
                >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(file_name)="data">
                  Notification d'ouverture<br>
                  <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                </template>

                <template v-slot:cell(state)="data">
                  <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                  <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                  <strong v-else class="text-secondary">En cours de validation</strong>
                </template>

                <template v-slot:cell(actions)="data">
                  <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                    <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                    <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                  </div>
                  <div v-else>
                    <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-primary" class="ml-2" size="sm"
                      @click='showModifyAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Modifier'">
                      <v-icon name="edit"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-danger" class="ml-2" size="sm"
                      @click='showDeleteAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Supprimer'">
                      <v-icon name="trash-alt"/>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div v-if="ouvertureAreDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
              <div class="col-sm-12 text-right">
                <b-button
                  v-if="isBeforeDeadline"
                  variant="outline-info" size="sm" pill
                  @click="showCreateAccordDocumentModal('ARE')">
                    <v-icon name="plus" /> Ajouter un fichier
                </b-button>
                <strong
                  v-else
                >
                  Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                </strong>
              </div>
            </div>

            <hr>

            <!-- //////////////////////// ATTESTATION DE PAIEMENT DE LA CAF (UNIQUEMENT POUR LES MONOPARENTALES) //////////////// -->
            <div 
              v-if="isFamilleMonoparentale"
            >
              <div class="row mt-3">
                <div class="col-12">
                  <h3 style="margin-bottom: 0px;"> Attestation(s) de paiement de la CAF </h3>
                  <em class="text-secondary">
                    Veuillez charger votre ou vos attestation(s) qui indique(nt) les denières paiements perçus par la CAF.<br>
                  </em>
                </div>
              </div>

              <div v-if="attestCafDocs.length === 0">
                <div class="row">
                  <div class="col-sm-12 mt-2 mb-3 text-center">
                    <em>Aucun document encore chargé </em>
                  </div>
                </div>
              </div>

              <div v-else>
                <b-table
                  responsive class="align-middle text-center mt-4 cursor-table"
                  headVariant="light"
                  :fields="documentsRenderFields"
                  :items="attestCafDocs"
                  >
                  <template v-slot:cell(index)="data">
                    {{ data.index + 1 }}
                  </template>

                  <template v-slot:cell(file_name)="data">
                    Attestation de paiement<br>
                    <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                  </template>

                  <template v-slot:cell(state)="data">
                    <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                    <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                    <strong v-else class="text-secondary">En cours de validation</strong>
                  </template>

                  <template v-slot:cell(actions)="data">
                    <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                      <b-button
                        variant="outline-secondary" class="mr-2" size="sm"
                        @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                      <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                    </div>
                    <div v-else>
                      <b-button
                        variant="outline-secondary" class="ml-2" size="sm"
                        @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                        v-b-popover.hover.top="'Télécharger'">
                        <v-icon name="cloud-download-alt"/>
                      </b-button>
                      <b-button
                        :disabled="!isBeforeDeadline"
                        variant="outline-primary" class="ml-2" size="sm"
                        @click='showModifyAccordDocumentModal(data.item.id)'
                        v-b-popover.hover.top="'Modifier'">
                        <v-icon name="edit"/>
                      </b-button>
                      <b-button
                        :disabled="!isBeforeDeadline"
                        variant="outline-danger" class="ml-2" size="sm"
                        @click='showDeleteAccordDocumentModal(data.item.id)'
                        v-b-popover.hover.top="'Supprimer'">
                        <v-icon name="trash-alt"/>
                      </b-button>
                    </div>
                  </template>
                </b-table>
              </div>

              <div v-if="attestCafDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
                <div class="col-sm-12 text-right">
                  <b-button
                    v-if="isBeforeDeadline"
                    variant="outline-info" size="sm" pill
                    @click="showCreateAccordDocumentModal('ATTESTCAF')">
                      <v-icon name="plus" /> Ajouter un fichier
                  </b-button>
                  <strong
                    v-else
                  >
                    Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                  </strong>
                </div>
              </div>

              <hr>
            </div>


            <!-- //////////////////////// RIB //////////////// -->
            <div class="row mt-3">
              <div class="col-12">
                <h3 style="margin-bottom: 0px;"> Relevé d'identité bancaire (RIB) </h3>
                <em class="text-secondary">
                  Renseignez tout d'abord manuellement <strong>votre Iban et Bic</strong>
                  puis <strong>chargez votre Rib</strong> <em>(par mesure de sécurité).</em>
                </em><br>
              </div>
            </div>

            <div class="row mt-2">
              <div class="col-12 col-sm-6">
                <b-form-group id="iban-group" label="Votre Iban" label-for="iban-input">
                  <b-form-input
                    v-model="iban" type="text"
                    v-mask="'NNNN NNNN NNNN NNNN NNNN NNNN NNN'"
                    maxlength="50"
                    placeholder="Iban"
                    id="iban-input"
                    @input="$v.iban.$touch()"
                    :state="$v.iban.$dirty ? !$v.iban.$error : null">
                  </b-form-input>
                  <small class="text-danger" v-if="$v.iban.$error">Veuillez saisir un iban valide</small>
                </b-form-group>
              </div>
              <div class="col-12 col-sm-6">
                <b-form-group id="bic-group" label="Votre bic" label-for="bic-input">
                  <b-form-input
                    v-model="bic" type="text"
                    maxlength="11"
                    placeholder="Bic"
                    id="bic-input"
                    @input="$v.bic.$touch()"
                    :state="$v.bic.$dirty ? !$v.bic.$error : null">
                  </b-form-input>
                  <small class="text-danger" v-if="$v.bic.$error">Veuillez saisir un BIC valide</small>
                </b-form-group>
              </div>
            </div>
            <b-form-group id="domiciliationBancaire-group" label="Domiciliation bancaire" label-for="domiciliationBancaire-input">
              <b-form-input
                v-model="domiciliationBancaire" type="text"
                maxlength="249"
                placeholder="Domiciliation bancaire"
                id="domiciliationBancaire-input"
                @input="$v.domiciliationBancaire.$touch()"
                :state="$v.domiciliationBancaire.$dirty ? !$v.domiciliationBancaire.$error : null">
              </b-form-input>
              <small class="text-danger" v-if="$v.domiciliationBancaire.$error">Votre domiciliation doit comporter entre 2 et 249 caractères.</small>
            </b-form-group>

            <div class="row mt-2">
              <div class="col-sm-12 text-center">
                <b-button
                  block variant="outline-success" size="sm" pill
                  @click="modifyAccordBankInformation"
                  :disabled="modifyAccordInProcess || $v.iban.$invalid || $v.bic.$invalid || $v.domiciliationBancaire.$invalid || accord.state === '6MREFUSED' || accord.state === '7MVALIDATED'">
                    Enregistrer ces informations
                </b-button>
                <small v-if="accord.state === '6MREFUSED'" class="text-danger">Demande d'accord refusée</small>
                <small v-if="accord.state === '7MVALIDATED'" class="text-success">Demande d'accord déjà acceptée</small>
              </div>
            </div>

            <div v-if="ribDocs.length === 0">
              <div class="row">
                <div class="col-sm-12 mt-2 mb-3 text-center">
                  <em>Aucun document encore chargé </em>
                </div>
              </div>
            </div>

            <div v-else>
              <b-table
                responsive class="align-middle text-center mt-4 cursor-table"
                headVariant="light"
                :fields="documentsRenderFields"
                :items="ribDocs"
                >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(file_name)="data">
                  Rib<br>
                  <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                </template>

                <template v-slot:cell(state)="data">
                  <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                  <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                  <strong v-else class="text-secondary">En cours de validation</strong>
                </template>

                <template v-slot:cell(actions)="data">
                  <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                    <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                    <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                  </div>
                  <div v-else>
                    <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-primary" class="ml-2" size="sm"
                      @click='showModifyAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Modifier'">
                      <v-icon name="edit"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-danger" class="ml-2" size="sm"
                      @click='showDeleteAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Supprimer'">
                      <v-icon name="trash-alt"/>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div v-if="ribDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
              <div class="col-sm-12 text-right">
                <b-button
                  v-if="isBeforeDeadline"
                  variant="outline-info" size="sm" pill
                  @click="showCreateAccordDocumentModal('RIB')">
                    <v-icon name="plus" /> Ajouter un fichier
                </b-button>
                <strong
                  v-else
                >
                  Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                </strong>
              </div>
            </div>

            <hr>


            <!-- //////////////////////// AUTRE DOCUMENTS //////////////// -->
            <div class="row mt-3">
              <div class="col-12">
                <h3 style="margin-bottom: 0px;"> Autres justificatifs </h3>
                <em class="text-secondary">
                  Toutes autres pièces justifiant votre demande. Attention vous ne pouvez ajouter que jusqu'à 10 documents.
                </em><br>
              </div>
            </div>


            <div v-if="otherDocs.length === 0">
              <div class="row">
                <div class="col-sm-12 mt-2 mb-3 text-center">
                  <em>Aucun document encore chargé </em>
                </div>
              </div>
            </div>

            <div v-else>
              <b-table
                responsive class="align-middle text-center mt-4 cursor-table"
                headVariant="light"
                :fields="documentsRenderFields"
                :items="otherDocs"
                >
                <template v-slot:cell(index)="data">
                  {{ data.index + 1 }}
                </template>

                <template v-slot:cell(file_name)="data">
                  Autre<br>
                  <em><small> ( {{ sizeFormatter(data.item.file_size) }} )</small> </em>
                </template>

                <template v-slot:cell(state)="data">
                  <strong v-if="data.item.state == 'REFUSED'" class="text-danger"> Refusé par votre conseiller.ère Audiens, à modifier</strong>
                  <strong v-else-if="data.item.state == 'VALIDATED'" class="text-success"> Validé</strong>
                  <strong v-else class="text-secondary">En cours de validation</strong>
                </template>

                <template v-slot:cell(actions)="data">
                  <div v-if="accord.state == '7MVALIDATED' || accord.state == '6MREFUSED'">
                    <b-button
                      variant="outline-secondary" class="mr-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <em v-if="accord.state == '7MVALIDATED'" class="text-secondary">Accord validé</em>
                    <em v-if="accord.state == '6MREFUSED'" class="text-secondary">Accord refusé</em>
                  </div>
                  <div v-else>
                    <b-button
                      variant="outline-secondary" class="ml-2" size="sm"
                      @click='getProtectedAccordDocument(data.item.id, data.item.file_name)'
                      v-b-popover.hover.top="'Télécharger'">
                      <v-icon name="cloud-download-alt"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-primary" class="ml-2" size="sm"
                      @click='showModifyAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Modifier'">
                      <v-icon name="edit"/>
                    </b-button>
                    <b-button
                      :disabled="!isBeforeDeadline"
                      variant="outline-danger" class="ml-2" size="sm"
                      @click='showDeleteAccordDocumentModal(data.item.id)'
                      v-b-popover.hover.top="'Supprimer'">
                      <v-icon name="trash-alt"/>
                    </b-button>
                  </div>
                </template>
              </b-table>
            </div>

            <div v-if="otherDocs.length <= 10 && accord.state != '7MVALIDATED' && accord.state != '6MREFUSED'" class="row">
              <div class="col-sm-12 text-right">
                <b-button
                  v-if="isBeforeDeadline"
                  variant="outline-info" size="sm" pill
                  @click="showCreateAccordDocumentModal('OTHER')">
                    <v-icon name="plus" /> Ajouter un fichier
                </b-button>
                <strong
                  v-else
                >
                  Il n'est plus possible d'ajouter des documents 6 mois après le mois de décembre de l'année {{accord.year}}
                </strong>
              </div>
            </div>

            <hr>

            <div v-if="accord.state === '2ONGOING' || accord.state == '4CREFUSED' || accord.state == '5CVALIDATED' || accord.state == '6MREFUSED' || accord.state == '7MVALIDATED'">
            </div>
            <div v-else-if="accord.state === '1CREATED' && accord.year < currentYear && (accord.first_ask_validation_date == null || accord.first_ask_validation_date == '')" class="text-center">
              <h2 class="text-danger mt-3">
                Vous ne pouvez faire une demande de validation pour un accord d'une année antérieure à l'année en cours.<br>
                Il vous est nécessaire de créer une nouvelle demande d'accord pour l'année en cours
              </h2>
            </div>
            <div v-else-if="rfrTotal > rfrPlafond" class="text-center">
              <h2 class="text-danger mt-3">
                Votre revenu de référence
                ({{formatThousandSeparatorNumber(rfrTotal)}} €) est supérieur
                au plafond autorisé
                ({{formatThousandSeparatorNumber(rfrPlafond)}} €) <br>pour pouvoir
                réaliser une demande d'aide
              </h2>
            </div>
            <div v-else-if="!hasFilledAllInformation"
                 class="text-center">
              <h2 class="text-danger mt-3"> Veuillez télécharger tous vos documents et renseigner votre RIB (iban, bic et domiciliation bancaire) pour
              pouvoir faire une demande de validation finale de votre dossier.</h2>
            </div>
            <div v-else-if="!hasUploadedNotificationOuvertureAreForCouple" class="text-center">
              <h2 class="text-danger mt-3">
                Veuillez télécharger les notifications d'ouverture de droits ARE de France Travail de <u>votre conjoint</u>
                et de <u>vous-même</u> (2 documents )
              </h2>
            </div>
            <div v-else-if="isFamilleMonoparentale && attestCafDocs.length === 0">
              <h2 class="text-danger mt-3">
                Veuillez charger votre ou vos attestation(s) de paiement de la CAF
              </h2>
            </div>
            <div v-else class="row mt-3">
              <div class="col-sm-12">
                <div
                  v-if="isBeforeDeadline"
                >
                  <b-button
                    block variant="outline-success" class="mt-2 font-weight-bold" size="lg"
                    @click="preAskAccordValidation"
                    :disabled="modifyAccordInProcess">
                      Demander la validation finale de votre dossier
                  </b-button>
                  <p
                    v-if="isOneDocumentRefused"
                    class="text-danger mt-2"
                  >
                    <strong><u>Attention</u>, au moins un de vos documents a été refusé par votre conseiller.ère Audiens.</strong>
                  </p>
                </div>
                <strong
                  class="text-danger"
                  v-else
                >
                  Il n'est plus possible de faire une demande de validation 6 mois après le mois de décembre de l'année {{accord.year}}
                </strong>
              </div>
            </div>
          </div>
        </div>
    </div>
  </div>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { APIBeneficiaireConnected } from '@/api/APIBeneficiaireConnected'
import { required, minLength, maxLength, decimal, integer } from 'vuelidate/lib/validators'
import { fileOperations } from '@/mixins/utilMixins'
import renderMixins from '@/mixins/renderMixins'
import { file_size_validation, file_extension_validation, isValidIban } from '@/validators/validators'

import formTitleComponent from '@/components/formTitleComponent'

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';


const apiBeneficiaireConnectedService = new APIBeneficiaireConnected()

export default {
  name: 'AccordDetails',
  components: {
    formTitleComponent,
    Loading,
  },
  mixins: [
    renderMixins,
    validationMixin,
    fileOperations
  ],
  data: function () {
    const now = new Date()
    const currentYear = now.getFullYear()
    return {

      currentYear: currentYear,
      // ------- ACCORD --------
      accord: {
        abattements: [],
        id: '',
        state: '1CREATED',
        state_changed_date: '',
        first_ask_validation_date: '',
        refused_message: '',
        is_no_other_aides: false,
        is_information_certified: false,
        is_avis_situation_mandatory: false,
        is_teleactualisation_mandatory: false,
        is_attestation_paiement_mandatory: false,
        memo_message: '',
        year: 2018,
        iban: '',
        bic: '',
        domiciliation_bancaire: '',
        beneficiaire: {
          user: {
            first_name: '',
            last_name: '',
            username: '',
          },
          children: [],
          accords: [],
          remboursements: [],
          activite: 'ART',
          metier: '',
          other_first_names: '',
          address: '',
          code_postal: '',
          city: '',
          birthdate: '2020-01-01',
          phone_number: '',
          other_phone_number: '',
          situation: 'CELIB',
          conjoint_first_name: '',
          conjoint_last_name: '',
          is_conjoint_intermittent: false,
          muna: '',
          memo_message: '',
        },
      },
      accordLoading: false,

      // -------- Accord validation ----
      isNoOtherAides: false,
      isInformationCertified: false,
      askAccordValidationModal: false,

      // ------- BANK -----------
      iban: '',
      bic: '',
      domiciliationBancaire: '',
      modifyAccordInProcess: false,

      // ------- Revenu fiscal référence ------
      rfrValue: 0,
      rfrConjointValue: null,
      rfrNbEnfantsCharge: 0,

      // ------- Abattements --------
      abattementValue: null,
      abattementConjointValue: null,
      abattementCoupleValue: null,

      // ------ Document --------
      handleDocumentID: 0,

      createAccordDocumentFile: [],
      createAccordDocumentType: '',
      createAccordDocumentButtonText: 'Charger votre document',
      createAccordDocumentButtonStyle: 'outline-dark',
      createAccordDocumentInProcess: false,

      modifyAccordDocumentFile: [],
      modifyAccordDocumentID: 0,
      modifyAccordDocumentButtonText: 'Modifier votre document',
      modifyAccordDocumentButtonStyle: 'outline-dark',
      modifyAccordDocumentInProcess: false,

      deleteAccordDocumentButtonText: 'Supprimer votre document',
      deleteAccordDocumentButtonStyle: 'outline-danger',
      deleteAccordDocumentInProcess: false,

      // ------ Ouverture des droits ------

      documentsRenderFields: [
        { key: 'index', label: "#", tdClass: 'ui-helper-center'},
        { key: "file_name", label: "Votre document", tdClass: 'ui-helper-center'},
        { key: "state", label: "Statut", tdClass: 'ui-helper-center'},
        { key: "actions", label: "", tdClass: 'ui-helper-center'},
      ],

      naissanceDocs: [],
      impotDocs: [],
      ouvertureAreDocs: [],
      attestCafDocs: [],
      ribDocs: [],
      otherDocs: [],

      avisSituationDocs: [],
      teleactualisationDocs: [],
      attestationsPaiementDocs: [],

      isOneDocumentRefused: false,
      isDocumentsLoading:  false
    }
  },
  computed: {
    abattements () {
      return this.accord.abattements.filter((abattement) => abattement.type == 'DEMANDEUR')
    },
    abattementsCouple () {
      return this.accord.abattements.filter((abattement) => abattement.type == 'COUPLE')
    },
    abattementsConjoint () {
      return this.accord.abattements.filter((abattement) => abattement.type == 'CONJOINT')
    },
    isFamilleMonoparentale () {
      return this.accord.beneficiaire.situation === 'CELIB' || 
             this.accord.beneficiaire.situation === 'DIV' || 
             this.accord.beneficiaire.situation === 'SEP'
    },
    token () {
      return this.$store.state.auth.token
    },
    hasFilledAllInformation () {
      if (this.naissanceDocs.length === 0 || this.impotDocs.length === 0 || this.ouvertureAreDocs.length === 0 || this.ribDocs.length === 0 || this.accord.iban === '' || this.accord.bic === '' || this.accord.domiciliation_bancaire === '') {
        return false
      }
      return true
    },
    hasUploadedNotificationOuvertureAreForCouple () {
      if (this.enCouple && this.ouvertureAreDocs.length < 2 && this.accord.beneficiaire.is_conjoint_intermittent) {
        return false
      }
      else {
        return true
      }
    },
    isLoading () {
      if (this.accordLoading || this.isDocumentsLoading || this.createAccordDocumentInProcess ||
          this.modifyAccordDocumentInProcess || this.deleteAccordDocumentInProcess || this.modifyAccordInProcess) {
        return true
      }
      return false
    },
    isBeforeDeadline () {
      const accordYear = this.accord.year
      const deadline = new Date(accordYear + 1, 6, 30)
      const now = new Date()

      if (now < deadline) {
        return true
      }
      return false
    },
    enCouple () {
      if (this.accord.beneficiaire.situation === 'MAR' || this.accord.beneficiaire.situation === 'PACS' || this.accord.beneficiaire.situation === 'VIEMAR') {
        return true
      }
      else {
        return false
      }
    },
    rfrTotal () {
      if (this.enCouple) {
        // Alors on est sur un couple
        if (this.accord.rfr_value && Number(this.accord.rfr_conjoint_value) > 0) {
          return Number(this.accord.rfr_value) + Number(this.accord.rfr_conjoint_value)
        }
        else {
          return Number(this.accord.rfr_value)
        }
      }
      else {
        // Alors on est sur un célib
        return Number(this.accord.rfr_value)
      }
    },
    rfrPlafond () {
      return 60000 + 3000 * Number(this.accord.rfr_nb_children_fiscalement_a_charge)
    },
  },
  created: function() {
    this.$dayjs.locale('fr')
    this.getAccord()
    this.getAllAccordDocuments()
  },
  validations: {
    // DOCUMENT
    createAccordDocumentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },
    modifyAccordDocumentFile: {
      required,
      file_size_validation,
      file_extension_validation,

      name :{
        maxLength: maxLength(100),
        minLength: minLength(2)
      }
    },

    // BANK INFORMATION
    iban: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(50),
      validIban(value) {
        return isValidIban(value)
      }
    },
    bic: {
      required,
      minLength: minLength(8),
      maxLength: maxLength(11)
    },

    domiciliationBancaire: {
      required,
      minLength: minLength(2),
      maxLength: maxLength(250)
    },

    rfrValue: {
      required,
      decimal,
      isSuperior0(value) {
        return value && value > 0
      }
    },
    rfrConjointValue: {
      decimal
    },
    rfrNbEnfantsCharge: {
      required,
      integer
    }
  },
  methods: {
    addAbattement (type) {
      let value = this.abattementValue
      if (type == 'COUPLE') {
        value = this.abattementCoupleValue
      }
      if (type == 'CONJOINT') {
        value = this.abattementConjointValue
      }
      if (!value || value <= 0) {
        return true
      }
      this.modifyAccordInProcess = true
      apiBeneficiaireConnectedService.createAbattement(
        this.token,
        this.$route.params.accord_id,
        {
          'montant': value,
          'type': type,
        }
      )
        .then((result) => {
          this.accord.abattements.push(result.data)
          if (type == 'COUPLE') {
            this.abattementCoupleValue = null
          }
          else if (type == 'CONJOINT') {
            this.abattementConjointValue = null
          }
          else {
            this.abattementValue = null
          }
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },

    deleteAbattement (id) {
      this.modifyAccordInProcess = true
      apiBeneficiaireConnectedService.deleteAbattement(
        this.token,
        id
      )
        .then(() => {
          const filteredAbattements = this.accord.abattements.filter((abattement) => abattement.id != id)
          this.accord.abattements = filteredAbattements
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },

    // ---------------------- ACCORD -----------------------
    getAccord () {
      this.accordLoading = true
      apiBeneficiaireConnectedService.getAccord(this.token, this.$route.params.accord_id)
        .then((result) => {
          this.accord = result.data

          this.iban = result.data.iban
          this.bic = result.data.bic
          this.domiciliationBancaire = result.data.domiciliation_bancaire

          this.rfrValue = result.data.rfr_value
          this.rfrConjointValue = result.data.rfr_conjoint_value
          this.rfrNbEnfantsCharge = result.data.rfr_nb_children_fiscalement_a_charge

          this.isNoOtherAides = result.data.is_no_other_aides
          this.isInformationCertified = result.data.is_information_certified
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.accordLoading = false
        })
    },

    // -------------------- ACCORD DOCUMENTS  --------------
    getAllAccordDocuments () {
      this.isDocumentsLoading = true
      this.isOneDocumentRefused = false
      this.naissanceDocs = []
      this.impotDocs = []
      this.ouvertureAreDocs = []
      this.attestCafDocs = []
      this.ribDocs = []
      this.otherDocs = []
      this.avisSituationDocs = []
      this.teleactualisationDocs = []
      this.attestationsPaiementDocs = []

      apiBeneficiaireConnectedService.getAllAccordDocuments(this.token, this.$route.params.accord_id)
        .then((result) => {
          var documentsLength = result.data.length;
          for (var i = 0; i < documentsLength; i++) {
            if (result.data[i]['type'] === 'NAISSANCE') {
              this.naissanceDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'IMPOTS') {
              this.impotDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'ARE') {
              this.ouvertureAreDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'ATTESTCAF') {
              this.attestCafDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'RIB') {
              this.ribDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'OTHER') {
              this.otherDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'AVSITUA') {
              this.avisSituationDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'TELEACTU') {
              this.teleactualisationDocs.push(result.data[i])
            }
            else if (result.data[i]['type'] === 'PAIEPE') {
              this.attestationsPaiementDocs.push(result.data[i])
            }

            if (result.data[i]['state'] === 'REFUSED') {
              this.isOneDocumentRefused = true
            }
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('AccordDetails getAllAccordDocuments API Error : ' + String(error))
        })
        .finally(() => {
          this.isDocumentsLoading = false
        })
    },

    getProtectedAccordDocument (document_id, document_name) {
      apiBeneficiaireConnectedService.getOwnProtectedAccordDocument(this.token, document_id)
      .then((res) => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', document_name); //or any other extension
          document.body.appendChild(link);
          link.click();
        }, () => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
    },


    // ------------------ DOCUMENT ---------------------

    showCreateAccordDocumentModal (createAccordDocumentType ) {
      this.createAccordDocumentType = createAccordDocumentType
      this.$refs.createAccordDocumentModal.show()
    },

    showModifyAccordDocumentModal (modifyAccordDocumentID ) {
      this.modifyAccordDocumentID = modifyAccordDocumentID
      this.$refs.modifyAccordDocumentModal.show()
    },

    showDeleteAccordDocumentModal (deleteAccordDocumentID ) {
      this.modifyAccordDocumentID = deleteAccordDocumentID
      this.$refs.deleteAccordDocumentModal.show()
    },


    createAccordDocument () {
      this.createAccordDocumentButtonText = "Envoi en cours"
      this.createAccordDocumentButtonStyle = "secondary"
      this.createAccordDocumentInProcess = true
      apiBeneficiaireConnectedService.createAccordDocument(
        this.token, this.$route.params.accord_id, this.createAccordDocumentType, this.createAccordDocumentFile)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$refs.accordDocumentUploadedModal.show()
          this.createAccordDocumentFile = []
          this.createAccordDocumentType = ''
          this.getAllAccordDocuments()
          if (this.accord.state === '2ONGOING' || this.accord.state === '4CREFUSED' || this.accord.state === '5CVALIDATED') {
            this.getAccord()
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('AccordDetails createAccordDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.$refs.createAccordDocumentModal.hide()
          this.createAccordDocumentButtonText = "Charger votre document"
          this.createAccordDocumentButtonStyle = "outline-dark"
          this.createAccordDocumentInProcess = false
        })
    },

    modifyAccordDocument () {
      this.modifyAccordDocumentButtonText = "Envoi en cours"
      this.modifyAccordDocumentButtonStyle = "secondary"
      this.modifyAccordDocumentInProcess = true
      apiBeneficiaireConnectedService.modifyAccordDocument(
        this.token, this.modifyAccordDocumentID, this.modifyAccordDocumentFile)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.$refs.accordDocumentUploadedModal.show()
          this.modifyAccordDocumentFile = []
          this.modifyAccordDocumentID =0
          this.getAllAccordDocuments()
          if (this.accord.state === '2ONGOING' || this.accord.state === '4CREFUSED' || this.accord.state === '5CVALIDATED') {
            this.getAccord()
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('AccordDetails modifyAccordDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.$refs.modifyAccordDocumentModal.hide()
          this.modifyAccordDocumentButtonText = "Modifier votre document"
          this.modifyAccordDocumentButtonStyle = "outline-dark"
          this.modifyAccordDocumentInProcess = false
        })
    },

    deleteAccordDocument () {
      this.deleteAccordDocumentButtonText = "Suppression en cours"
      this.deleteAccordDocumentButtonStyle = "secondary"
      this.deleteAccordDocumentInProcess = true
      apiBeneficiaireConnectedService.deleteAccordDocument(
        this.token, this.modifyAccordDocumentID)
        .then(() => {
          if (this.$browserDetect.isIE) {
            location.reload()
          }
          this.modifyAccordDocumentID =0
          this.$refs.documentDeletedModal.show()
          this.getAllAccordDocuments()
          if (this.accord.state === '2ONGOING' || this.accord.state === '4CREFUSED' || this.accord.state === '5CVALIDATED') {
            this.getAccord()
          }
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('AccordDetails deleteAccordDocument API Error : ' + String(error))
        })
        .finally(() => {
          this.$refs.deleteAccordDocumentModal.hide()
          this.deleteAccordDocumentButtonText = "Supprimer votre document"
          this.deleteAccordDocumentButtonStyle = "outline-danger"
          this.deleteAccordDocumentInProcess = false
        })
    },

    preAskAccordValidation () {
      if (!this.accord.is_no_other_aides || !this.accord.is_information_certified) {
        this.askAccordValidationModal = true
      }
      else {
        this.askAccordValidation()
      }
    },

    askAccordValidation () {
      this.modifyAccordInProcess = true
      const data = {
        "is_no_other_aides": this.isNoOtherAides,
        "is_information_certified": this.isInformationCertified
      }
      apiBeneficiaireConnectedService.askAccordValidation(this.token, this.$route.params.accord_id, data)
        .then(() => {
          this.askAccordValidationModal = false
          this.$refs.askAccordValidationModal.show()
          this.getAccord()
        })
        .catch((error) => {
          this.$refs.globalAPILoadingErrorModal.show()
          throw new Error('AccordDetails askAccordValidation API Error : ' + String(error))
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },

    modifyAccordBankInformation () {
      this.modifyAccordInProcess = true

      apiBeneficiaireConnectedService.modifyAccord(
        this.token,
        this.$route.params.accord_id,
        {
          'iban': this.iban,
          'bic': this.bic,
          'domiciliation_bancaire': this.domiciliationBancaire
        }
      )
        .then((result) => {
          this.accord = result.data
          this.iban = this.accord.iban
          this.bic = this.accord.bic
          this.domiciliationBancaire = this.accord.domiciliation_bancaire
        })
        .catch(() => {
          this.$refs.bankInformationAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },

    modifyAccordRfrInformation () {
      this.modifyAccordInProcess = true

      apiBeneficiaireConnectedService.modifyAccord(
        this.token,
        this.$route.params.accord_id,
        {
          'rfr_value': Number(this.rfrValue),
          'rfr_conjoint_value': Number(this.rfrConjointValue),
          'rfr_nb_children_fiscalement_a_charge': this.rfrNbEnfantsCharge
        }
      )
        .then((result) => {
          this.accord = result.data
          this.rfrValue = result.data.rfr_value
          this.rfrConjointValue = result.data.rfr_conjoint_value
          this.rfrNbEnfantsCharge = result.data.rfr_nb_children_fiscalement_a_charge
        })
        .catch(() => {
          this.$refs.globalAPILoadingErrorModal.show()
        })
        .finally(() => {
          this.modifyAccordInProcess = false
        })
    },
  }
}
</script>
